/**.contact-container {
    width: 50%;
    margin: 0 auto;
    padding: 30px; 
    border-radius: 15px;
    background-color: white; 
    border: 1px solid #d0d0d0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .input-group {
    display: flex;
    justify-content: center;
    gap: 20px; /* Gap between name and email inputs 
    width: 100%;
  }
  
  .contact-container input[type="text"],
  .contact-container input[type="email"] {
    width: 40%; /* Smaller width for inputs 
    padding: 10px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    font-size: xx-large;
  }
  
  .contact-container textarea {
    width: calc(100% - 40px); /* Full width minus some padding 
    height: 100px;
    padding: 10px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    resize: none;
    font-family: 'Inter', sans-serif;
  }
  
  .contact-container .submitForm {
    padding: 8px 16px; /* Smaller button 
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-size: 0.9em; /* Slightly smaller font 
  }
  
  .contact-container .error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      width: 90%;
      padding: 20px;
    }
  
    .input-group {
      flex-direction: column;
      gap: 10px;
    }
  
    .contact-container input[type="text"],
    .contact-container input[type="email"] {
      width: 100%;
    }
  }**/

  .contact-container {
    width: 50%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 15px;
    background-color: #1a1a1a; 
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .input-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
  
  .input-field {
    width: 40%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: medium;
    background-color: #2a2a2a;
    color: white;
    border: 2px solid transparent; 
    transition: all 0.3s ease;
  }
   
  .input-field:focus {
    outline: none;
    border-color: #ff0000; 
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5); 
  }
  
  .textarea-field {
    width: calc(100% - 40px);
    height: 100px;
    padding: 10px;
    border:none;
    border-radius: 5px;
    resize: none;
    font-family: 'Inter', sans-serif;
    background-color: #2a2a2a;
    color: white;
    border: 2px solid transparent; 
    transition: all 0.3s ease;
  }

  .textarea-field:focus {
    outline: none;
    border-color: #ff0000; 
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5); 
  }
  
  .submit-button {
    padding: 8px 16px;
    background-color: darkred;
    color: white;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-size: 0.9em;
  }
  .submit-button:hover{
    color:rgb(64, 0, 0);
  }

  .error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      width: 90%;
      padding: 20px;
    }
  
    .input-group {
      flex-direction: column;
      gap: 10px;
    }
  
    .input-field {
      width: 100%;
    }
  }

  ::placeholder{
    color:#9CA3AF;
  }


/**.contact-container{
    width: 30%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 15px;
  background-color: white; 
  border: 1px solid #d0d0d0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media (max-width: 768px) {
    .contact-container {
      width: 80%; 
    }
  }
  **/