.assignment-container{
    width: 50%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 15px;
  background-color: #1a1a1a; 
  border: 1px solid #d0d0d0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  resize: none;
}

@media (max-width: 768px) {
    .assignment-container {
      width: 80%; 
    }
  }

.titleinput{
    height:25px;
    width: 450px;
    background-color:#2a2a2a;
    border-radius:4px;
    border: 2px solid transparent; 
    transition: all 0.3s ease;
    color:white;
}
.titleinput:focus {
    outline: none;
    border-color: #ff0000; 
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5); 
  }

.hyperlink-width{
    width:350px;
    border:none;
    border-radius:4px;
    background: #2a2a2a;
    border: 2px solid transparent; 
    transition: all 0.3s ease;
    color:white;
    font-size: small;
}
.hyperlink-width:focus {
    outline: none;
    border-color: #ff0000; 
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5); 
  }
.course-container{
    width: 30%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 15px;
  background-color: #1a1a1a; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.forms-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .assignment-container {
    width: 60%;
    padding: 20px;
    border-radius: 15px;
    background-color: #1a1a1a; 
    border:none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .course-container {
    width: 30%;
    padding: 20px;
    border-radius: 15px;
    background-color: #1a1a1a; 
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .titleinput {
    height: 25px;
    width: 100%;
    max-width: 450px;
  }
  
  /**.textArea {
    width: 100%;
    height: 100px;
    resize: vertical;
    border:none;
    border-bottom: 2px solid #d0d0d0;
  }**/
  
  .hyperlink-width {
    width: 100%;
    max-width: 350px;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  select {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .submitForm {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submitForm:hover {
    background-color: black;
  }
  
  @media (max-width: 768px) {
    .forms-wrapper {
      flex-direction: column;
    }
  
    .assignment-container,
    .course-container {
      width: 90%;
      margin: 0 auto;
    }
  }

  .textArea {
    resize: none;
    font-family: 'Inter', sans-serif;
    width: 100%; /* Use full width of container */
    max-width: 800px; /* Maximum width */
    height: var(--textarea-height);
    --textarea-height: 200px;
    border: none;
    background-color: #2a2a2a;
    border-radius: 4px;
    color:white;
    border: 2px solid transparent; 
    transition: all 0.3s ease;
}
.textArea:focus {
    outline: none;
    border-color: #ff0000; 
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5); 
  }

@media (max-width: 600px) {
    .textArea {
        width: 90%; /* Use 90% of container width on smaller screens */
        min-width: unset; /* Remove minimum width constraint */
    }
}

.custom-red-button {
    color: white;
    background-color: darkred;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.625rem 1.25rem;
    text-align: center;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .custom-red-button:hover {
    background-color: rgb(64, 0, 0);
  }
  
  .custom-red-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.5);
  }

  
  /* For dark mode, if needed */
  @media (prefers-color-scheme: dark) {
    .custom-red-button:focus {
      box-shadow: 0 0 0 3px rgba(248, 113, 113, 0.5);
    }
  }

  ::placeholder{
    color:#9CA3AF;
  }

  select{
    background-color: #2a2a2a;
    color:white;
    border: 2px solid transparent; 
    transition: all 0.3s ease;
  }

select:focus {
  outline: none;
  border-color: #ff0000; 
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5); 
}

.uploadcursor{
    cursor:pointer;
}