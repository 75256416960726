.post-container{
    background-color: white;
    width:50%;
    margin: 0% auto 20px;
    padding-top:30px;
    border-radius:12px;
    padding-bottom:20px;
}

.title h2,
.title p,
.post-body p,
.websitehyperlink p,
.pdflink p
 {
  text-align: left; 
  margin-left: 50px;
  margin-right:50px;
}

.title hr{
    width:86%
}

.post-body{
    font-size: 16px; 
    line-height: 1.5;
    margin-bottom: 1em;
    font-weight: normal;
}

.dropdownselect{
    width:15%;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.container-border{
    border-radius:5px;
}

.post-home-container{
    /*background-color:rgb(107, 0, 0);**/
    background-color:#181818;
    width: fit-content;
    max-width: 100%;
    padding: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:150px;
    border-radius: 10px;
    margin-top:100px;
}

.dog-image-size{
    height:200px;
    width:200px;
}