/**image styling */
.row-container-image{
    display:grid;
    grid-template-columns: 1fr 1fr;
    width:90%;
    margin: 0 auto;
    
}

.row-container-image1{
    background-color: transparent;
    margin:4px;
    border-radius:12px;
    padding:4px;
}

.row-container-image2{
    background-color: transparent;
    margin:4px;
    border-radius:12px;
    padding:4px;
}

.chapter-image-size{
    width:600px;
    border-radius:12px;
}

/**text styling */
.row-container-text{
    display:grid;
    grid-template-columns: 1fr 1fr;
    width:90%;
    margin: 0 auto;
}

.row-container-description1{
    background-color:transparent;
    border:1px solid red;
    margin:8px;
    border-radius:12px;
}

.gradient-text-chapters {
    color:#b91c1c;
  }

.row-container-description2{
    /*background-color:#b91c1c;*/
    background-color:transparent;
    border: 1px solid red;
    margin:8px;
    border-radius:12px;
}

.chapter-school-name{
    text-decoration: underline;
}