.content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    align-items: center;
  }

  .parent-centered-container{
    background-image: url('../../images/acestats.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centered-container{
    background-color: rgba(78, 12, 12, 0.8);
    width:30%;
    border-radius: 10px;
    padding: 20px;
  }

  .para{
    margin-left:40px;
    margin-right: 40px;
    margin-top:0px;
    margin-bottom:30px;
    /**color:rgb(228, 147, 160);**/
    color:rgb(220, 220, 220);
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .text-container {
    /**color: transparent;**/
    background-color: transparent;
    color: white;
    /**background-image: linear-gradient(45deg, red, gold);**/
    padding: 20px;
    margin: 0%;
  }
  
  .text-container h3 {
    margin-top: 0;
  }

  .all-in{
    background-image: linear-gradient(to right, red, gold);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
    line-height: 1.5;
    font-size:4rem;
  }

  .study-classes{
    font-size:4rem;
    line-height:1.5;
  }

  .home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }



.service-grid {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.horizontal-service-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

.service-block {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    min-height: 250px;
    padding: 30px 20px;
    border-radius: 5px;
    width: calc(33.33% - 40px);
    box-sizing: border-box;
}


.service-block h2:first-of-type {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.service-block h2:nth-of-type(2) {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 20px 0;
}

.service-block p {
    margin: 0;
    flex-grow: 1;
}

@media (max-width: 768px) {
    .service-block {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .service-block {
        width: 100%;
    }
}

.gradient-text-ace {
  background-image: linear-gradient(to right, red, gold);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.title-color{
  color: rgb(212, 183, 19);
}


/**.servicegrid {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items:center;
  }
  
  .horizontal-service-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    flex-direction: row;
    align-items: stretch;
    width: 100%; 
  }
  
  .service-block {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    height: 250px; 

    padding: 50px 10px; 
    border-width:1px;
    border-color: white;
    border-style:solid;
    border-radius:5px;
    width: calc(23% - 20px); 
    box-sizing: border-box;
  }
  
  .service-block h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  
  .service-block p {
    margin: 0;
    max-width: 300px; 
    margin-left: auto;
    margin-right: auto;
  }**/
  
  .gridborder{
    border: 1rem;
    border-color:red;
  }

  .underlined {
    color: gray;
    text-decoration: underline;
  }

  .service-grids{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:10px;
    margin: 0 auto;
    width: 80%;
  }
  
  
  /**.imagecontainer2 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  .imagecontainer2 img {
    max-width: 100%;
    height: auto;
    max-height: 400px; 
  }**/
  
  @media (min-width: 768px) {
    .textcontainer2 {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .infoblock {
      width: calc(33.333% - 20px);
    }
  
    .imagecontainer2 {
      width: 100%;
      margin-top: 40px;
    }
  }