.footer {
    background-color: #181818;
    color: #ffffff;
    padding: 2rem 0;
    margin-top: auto; /* Pushes footer to bottom of content */
  }
  
  .footer__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .footer__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer__section {
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .footer__section--main {
    text-align: center;
  }
  
  .footer__logo {
    max-width: 150px;
    height: auto;
    margin-bottom: -2.5rem;
  }
  
  .footer__mission {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  /* Import Inter font if not already done in your project */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.custom-heading {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.025em;
  color: #111827; /* text-gray-900 */
  margin-bottom: 1rem;
  font-size: 1.5rem; /* text-4xl */
}

.gradient-text-ace {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(45deg, red, gold); /* Adjust colors as needed */
}

/* Responsive font sizes */
@media (min-width: 768px) {
  .custom-heading {
    font-size: 3rem; /* md:text-5xl */
  }
}

@media (min-width: 1024px) {
  .custom-heading {
    font-size: 1.57rem; /* lg:text-6xl */
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .custom-heading {
    color: #ffffff; /* dark:text-white */
  }
}
  
  .footer__heading {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .footer__list {
    list-style-type: none;
    padding: 0;
  }
  
  .footer__list li {
    margin-bottom: 0.5rem;
  }
  
  .footer__list a {
    color: white;
    text-decoration: none;
    transition: opacity 0.3s ease;
  }
  
  .footer__list a:hover {
    opacity: 0.8;
  }
  
  .footer__copyright {
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  @media (min-width: 768px) {
    .footer__section {
      width: 22%;
    }
  
    .footer__section--main {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
  
  @media (min-width: 1024px) {
    .footer__section--main {
      width: 30%;
      text-align: left;
      margin-bottom: 0;
    }
  }