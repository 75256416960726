.custom-gradient-button-pink-orange {
    color: white;
    /**background-color: red;**/
    background-image: linear-gradient(to bottom right, red, orange);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.625rem 1.25rem;
    text-align: center;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-image 0.3s ease;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .custom-gradient-button-pink-orange:hover {
    background-image: linear-gradient(to bottom left, #ec4899, #f97316);
  }
  
  .custom-gradient-button-pink-orange:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(236, 72, 153, 0.5);
  }
  
  /* Dark mode focus styles */
  @media (prefers-color-scheme: dark) {
    .custom-gradient-button-pink-orange:focus {
      box-shadow: 0 0 0 3px rgba(157, 23, 77, 0.5);
    }
  }

  .login-button{
        color: white;
        background-color: #b91c1c; 
        font-weight: 500; 
        border-radius: 1.5rem; 
        font-size: 0.875rem; 
        padding: 0.6rem 1.25rem; 
        text-align: center;
        outline: none;
        border: none;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
      
  }
  
  .login-button:hover {
    background-color: #991616; 
  }

  .logout-button{
    color: white;
    background-color: #b91c1c; 
    font-weight: 500; 
    border-radius: 1.5rem; 
    font-size: 0.875rem; 
    padding: 0.6rem 1.25rem; 
    text-align: center;
    outline: none;
    border: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
}

.logout-button:hover {
background-color: #991616; 
}