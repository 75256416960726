.notfound{
    margin-top:100px;
    margin-bottom:300px;

}

.errorimagesize{
    width:200px;
}

.linkcolor{
    color:red;
}

.color404{
    color:red;
}